import React from "react";
import { Grid } from "@mui/material";
import design from "../../images/design.png";
import designp from "../../images/designo.png";
import designt from "../../images/designt.png";
import designs from "../../images/designs.png";
import designh from "../../images/designh.png";
import designpa from "../../images/designp.png";
import Zoom from "react-reveal/Zoom";
import Bounce from "react-reveal/Bounce";
const Soluciones = () => {
  return (
    <div style={{ background: " #F2F7E4" }}>
      <Grid container spacing={3}>
        <Grid
          item
          md={12}
          lg={12}
          xs={12}
          sm={12}
          style={{ justifyContent: "center", textAlign: "center" }}
        >
          <Bounce top>
            <p style={{ color: "#000", fontWeight: "400", fontSize: "14px" }}>
              SOLUCIONES
            </p>
            <p
              style={{
                color: "#08843A",
                fontWeight: "800",
                fontSize: "20px",
                textTransform: " uppercase",
              }}
            >
              EIVICHAIN ES UN VALOR AÑADIDO PARA PRODUCTORES
            </p>
            <p style={{ color: "#000", fontWeight: "400", fontSize: "14px" }}>
              Una aplicación de registro de datos para todas las empresas que
              participan en la cadena de producción, elaboración, <br />{" "}
              distribución y venta del producto; con el objetivo de comunicar
              con total transparencia toda la información sobre el <br />{" "}
              producto.
            </p>
          </Bounce>
        </Grid>

        <Grid item md={6} lg={6} xs={12} sm={12}>
          <div className="container">
            <Grid container>
              {" "}
              <Grid
                item
                md={12}
                lg={6}
                xs={12}
                sm={12}
                style={{ justifyContent: "center", textAlign: "center" }}
              >
                {" "}
                <Bounce>
                  {" "}
                  <img src={designp} className="image_right" />
                  <br />
                  <br />
                </Bounce>
              </Grid>
              <Grid item md={12} lg={6} xs={12} sm={12}>
                <Zoom top>
                  <p className="sol_head">PRODUCTORES</p>
                  <p className="sol_para">
                    para mostrar autenticidad al comunicar toda la información
                    de un producto, desde el origen de las materias primas,
                    hasta los métodos de cultivo y crianza, hasta el empaque del
                    producto terminado
                  </p>
                  <br />
                  <br />
                </Zoom>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item md={6} lg={6} xs={12} sm={12}>
          <div className="container">
            <Grid container>
              {" "}
              <Grid
                item
                md={12}
                lg={6}
                xs={12}
                sm={12}
                style={{ justifyContent: "center", textAlign: "center" }}
              >
                {" "}
                <Bounce>
                  {" "}
                  <img src={designs} />
                </Bounce>
                <br />
                <br />
              </Grid>
              <Grid item md={12} lg={6} xs={12} sm={12}>
                <Zoom top>
                  <p className="sol_head text_left">consumidores</p>
                  <p className="sol_para text_left">
                    Una aplicación de consulta para el consumidor final del
                    producto, que desea conocer más información sobre su origen
                    y otras características;⁸ para así tener seguridad sobre lo
                    que se está comprando.
                  </p>
                  <br />
                  <br />
                </Zoom>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item md={6} lg={6} xs={12} sm={12}>
          <br />
          <br />
          <br />
          <div className="container">
            <Grid container>
              {" "}
              <Grid
                item
                md={12}
                lg={6}
                xs={12}
                sm={12}
                style={{ justifyContent: "center", textAlign: "center" }}
              >
                {" "}
                <Bounce>
                  {" "}
                  <img src={design} className="image_right" />
                </Bounce>
              </Grid>
              <Grid item md={12} lg={6} xs={12} sm={12}>
                <Zoom top>
                  <p className="sol_head ">LOGÍSTICA</p>
                  <p className="sol_para">
                    Una aplicación que garantiza la calidad del almacén, el
                    tiempo y almacenamiento del producto, la organización de las
                    entregas.
                  </p>
                  <br />
                  <br />
                </Zoom>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item md={6} lg={6} xs={12} sm={12}>
          <br />
          <br />
          <br />
          <div className="container">
            <Grid container>
              {" "}
              <Grid
                item
                md={12}
                lg={6}
                xs={12}
                sm={12}
                style={{ justifyContent: "center", textAlign: "center" }}
              >
                {" "}
                <Bounce>
                  {" "}
                  <img src={designh} />
                  <br />
                  <br />
                </Bounce>
              </Grid>
              <Grid item md={12} lg={6} xs={12} sm={12}>
                <Zoom top>
                  <p className="sol_head text_left">
                    Administraciones publicas
                  </p>
                  <p className="sol_para text_left">
                    Un modelo de trazabilidad basado en blockchain es una
                    herramienta eficaz para combatir el fraude y prevenir la
                    falsificación de "Made In"
                  </p>
                  <br />
                  <br />
                </Zoom>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item md={6} lg={6} xs={12} sm={12}>
          <br />
          <br />
          <br />
          <div className="container">
            <Grid container>
              {" "}
              <Grid
                item
                md={12}
                lg={6}
                xs={12}
                sm={12}
                style={{ justifyContent: "center", textAlign: "center" }}
              >
                {" "}
                <Bounce>
                  {" "}
                  <img src={designpa} className="image_right" />
                  <br />
                  <br />
                </Bounce>
              </Grid>
              <Grid item md={12} lg={6} xs={12} sm={12}>
                <Zoom top>
                  <p className="sol_head"> AUTORIDADES DE CERTIFICACIÓN </p>
                  <p className="sol_para">
                    Una aplicación que integra certificaciones para agilizar las
                    auditorias y acelerar los procesos para el control de
                    calidad.
                  </p>
                  <br />
                  <br />
                </Zoom>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <br />
      <br />
    </div>
  );
};

export default Soluciones;
