import React from "react";
import HeroLogo from "../../images/heromain.png";
import foodchain from "../../images/floc.png";
import quad from "../../images/flq.png";
import down from "../../images/scrolldown.png";
import Zoom from "react-reveal/Zoom";
import { Grid } from "@mui/material";

const Chain = () => {
  return (
    <div>
      <Grid container>
        <Grid
          lg={12}
          md={12}
          xs={12}
          sm={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            AlignItems: "center",
          }}
        >
          <br />
          <p
            style={{
              color: "#08843A",
              fontWeight: "800",
              fontSize: "15px",
              textTransform: "uppercase",
            }}
          >
            <span className="foodchaintext"> Based on </span>
            <span className="quadschaintext"> Powered by</span>
          </p>
          <img src={foodchain} className="home_chain foodchainimage" />

          <img src={quad} className="home_chain quadd quadschainimage" />
        </Grid>
        <Grid
          lg={12}
          md={12}
          xs={12}
          sm={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            AlignItems: "center",
          }}
        >
          <br />
          <p
            style={{
              color: "#08843A",
              fontWeight: "800",
              fontSize: "20px",
              textTransform: "uppercase",
            }}
          ></p>
        </Grid>
      </Grid>
    </div>
  );
};

export default Chain;
