import React from "react";
import { Grid } from "@mui/material";
import Technologias from "../../images/Techoferta.png";
import RubberBand from "react-reveal/RubberBand";
const TechnologiaOferta = () => {
  return (
    <div className="container">
      <Grid container spacing={2}>
        <Grid
          item
          md={6}
          lg={6}
          xs={12}
          sm={12}
          className="partner_alignment_text"
        >
          <br />
          <br />
          <br />
          <br />

          <p
            style={{
              color: "#08843A",
              fontWeight: "800",
              fontSize: "20px",
              textTransform: "uppercase",
            }}
          >
            TECNOLOGIA
          </p>
          <p style={{ color: "#000", fontWeight: "400" }} className="Tech_p">
            Fácil de usar, modular y lista para responder a las necesidades de{" "}
            <br />
            grandes y pequeñas empresas sin importar la etapa de <br />
            digitalización. Con Eivichain, la tecnología blockchain está <br />
            disponible para todos.
          </p>
        </Grid>
        <Grid
          item
          md={6}
          lg={6}
          xs={12}
          sm={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            AlignItems: "center",
          }}
        >
          <br />
          <br />
          <RubberBand>
            {" "}
            <img
              src={Technologias}
              style={{
                width: "70%",
              }}
            />
          </RubberBand>

          <br />
          <br />
          <br />
        </Grid>
      </Grid>
    </div>
  );
};

export default TechnologiaOferta;
