import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import emailjs from "emailjs-com";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import nuf from "../../images/foodcrisi.png";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      style={{ color: " #08843A", fontWeight: "800" }}
    >
      {"Copyright © "}
      <Link color="inherit" href="" style={{ textDecoration: "none" }}>
        EVICHAIN
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInSide() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_f0a2oyh",
        "template_33rjrk5",
        e.target,
        "event_2d8e366f38d7ba0ab1265fa0"
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${nuf})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              style={{ color: " #08843A", fontWeight: "700" }}
            >
              CONTACT US
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={sendEmail}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="What is your Name ?"
                name="name"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="What is your Email Address ?"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="phone"
                label="What is your Phone Number ?"
                type="text"
                id="number"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="company"
                label="What is your Company ?"
                type="text"
                id="number"
              />
              <label
                style={{
                  color: " #08843A",
                  fontSize: "800",
                  marginTop: "20px",
                }}
              >
                Enter Your Message
              </label>
              <textarea
                margin="normal"
                required
                fullWidth
                name="message"
                label="Message ?"
                type="text"
                id="number"
                rows="6"
                className="form-control"
              />

              <Button
                fullWidth
                value="Send"
                sx={{ mt: 3, mb: 4 }}
                className="btn-grad"
                style={{ color: "#fff", padding: "14px" }}
              >
                Send Message
              </Button>

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
