import React from "react";
import { Grid } from "@mui/material";
import Productos from "../../images/ofertathree.png";
const SolucionesPerson = () => {
  return (
    <div className="container">
      <Grid container spacing={2}>
        <Grid
          item
          md={6}
          lg={6}
          xs={12}
          sm={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            AlignItems: "center",
          }}
        >
          <br />
          <br />
          <div data-aos="flip-left">
          {" "}
          <img
            src={Productos}
            style={{
              width: "90%",
            }}
          />
        </div>
          <br />
          <br />
          <br />
        </Grid>
        <Grid item md={6} lg={6} xs={12} sm={12}>
          <br />
          <br />
          <br />
          <br />
          <div data-aos="fade-right">
            <p
              style={{
                color: "#08843A",
                fontWeight: "800",
                fontSize: "20px",
                textTransform: "uppercase",
              }}
            >
              Soluciones personalizadas
            </p>
            <p style={{ color: "#000", fontWeight: "400" }} className="Tech_p">
              Eivichain tiene las habilidades necesarias para desarrollar y
              comercializar dApps y plataformas de todo tipo, satisfaciendo sus
              necesidades para innovar y hacer evolucionar su negocio. Hemos
              llevado a cabo proyectos para el mercado inmobiliario, mejorado
              plataformas comerciales (por ejemplo, materias primas) y
              habilitado la digitalización de instituciones públicas.
            </p>

            <p
              style={{
                color: "#08843A",
                fontWeight: "300",
                fontSize: "15px",
                background: "#B1D63C42",
                padding: "30px",
                borderRadius: "10px",
              }}
            >
              Nuestra empresa está estructurada para llevar a cabo proyectos de
              blockchain de manera eficiente, invirtiendo habilidades y recursos
              para desarrollar nuevos servicios y modo de negocio.
            </p>
          </div>
          <br />
          <br />
          <br />
        </Grid>
      </Grid>
    </div>
  );
};

export default SolucionesPerson;
