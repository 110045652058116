import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../images/logo.png";
const Header = () => {
  return (
    <Navbar collapseOnSelect expand="lg" style={{ background: "#fff" }}>
      <Container>
        <Navbar.Brand href="./">
          <img src={logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav style={{ textAlign: "center" }}>
            <Nav.Link
              href="./"
              style={{ color: "#08843A", fontWeight: "700", fontSize: "14px" }}
            >
              Home
            </Nav.Link>

            <Nav.Link
              eventKey={2}
              href="./Tecnologia"
              style={{ color: "#08843A", fontWeight: "700", fontSize: "14px" }}
            >
              Tecnología
            </Nav.Link>
            <Nav.Link
              eventKey={2}
              href="./Oferta"
              style={{ color: "#08843A", fontWeight: "700", fontSize: "14px" }}
            >
              Oferta
            </Nav.Link>
            <Nav.Link
              eventKey={2}
              href="./Contacto"
              style={{ color: "#08843A", fontWeight: "700", fontSize: "14px" }}
            >
              Contacto
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
