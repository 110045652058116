import React from "react";
import Header from "../Components/Navbar/Header";

import HeroTech from "../Components/HeroTech/HeroTech";
import TechBlock from "../Components/TechBlock/TechBlock";
import Structura from "../Components/Structura/Structura";
import Producto from "../Components/Producto/Producto";

import Contact from "../Components/Contact/Contact";
import Partner from "../Components/Partner/Partner";
import Footer from "../Components/Footer/Footer";
const Pagetwo = () => {
  return (
    <div>
      {" "}
      <Header />
      <HeroTech />
      <TechBlock />
      <Structura />
      <Producto />
      <Partner />
      <Contact />
      <Footer />
    </div>
  );
};

export default Pagetwo;
