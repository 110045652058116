import React from "react";
import Header from "../Components/Navbar/Header";
import HeroHome from "../Components/HeroHome/HeroHome";
import Chain from "../Components/Chain/Chain";
import Technologia from "../Components/Technologia/Technologia";
import Intrechange from "../Components/Interchange/Intrechange";
import Contactenos from "../Components/Contactenos/Contactenos";
import Soluciones from "../Components/Soluciones/Soluciones";

import Footer from "../Components/Footer/Footer";

const Pageone = () => {
  return (
    <div>
      <Header />
      <HeroHome />
      <Chain />
      <Technologia />
      <Intrechange />
      <Soluciones />
      <Contactenos />
      <Footer />
    </div>
  );
};

export default Pageone;
