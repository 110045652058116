import React from "react";
import { Grid } from "@mui/material";
import struc from "../../images/struc.png";
import Bounce from "react-reveal/Bounce";
const Structura = () => {
  return (
    <div className="structura_bg">
      <div className="container">
        <Grid container spacing={0}>
          <Grid
            item
            md={6}
            lg={6}
            xs={12}
            sm={12}
            style={{
              justifyContent: "center",
              textAlign: "center",
              AlignItems: "center",
            }}
          >
            <br />
            <br />
            <br />
            <br />
            <br />
            <div data-aos="fade-down-right">
              {" "}
              <img src={struc} style={{ width: "70%" }} />
            </div>
          </Grid>
          <Grid item md={6} lg={6} xs={12} sm={12} className="Struc_align">
            <br />
            <br />
            <br />
            <br />
            <br />
            <Bounce right>
              <p style={{ color: "#000", fontSize: "18px" }}>STRUCTURA</p>
              <p
                style={{
                  color: "#08843A",
                  fontWeight: "800",
                  fontSize: "20px",
                }}
              >
                PLATAFORMA EIVICHAIN
              </p>
              <p style={{ color: "#000", fontSize: "18px" }}>
                Es un registro público, distribuido y descentralizado donde se
                registran los intercambios y la información. Los datos una vez
                ingresados ​​al sistema y validados son imborrables e
                inalterables.
              </p>
            </Bounce>
          </Grid>
        </Grid>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Structura;
