import React from "react";
import { Grid } from "@mui/material";
import trans from "../../images/trans.png";
import inter from "../../images/inter.png";
import confia from "../../images/confia.png";
import traz from "../../images/traz.png";
import vali from "../../images/vali.png";
import anti from "../../images/anti.png";

import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-react-ui-kit";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2500,
});
const TechBlock = () => {
  return (
    <div container>
      <Grid container spacing={2}>
        <Grid
          item
          md={12}
          lg={12}
          sm={12}
          xs={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <br />
          <br />
          <br />
          <br />
          <br />

          <p style={{ color: "#000", fontSize: "15px" }}>
            TECNOLOGIA BLOCKCHAIN
          </p>
          <p style={{ color: "#08843A", fontWeight: "800", fontSize: "20px" }}>
            PORQUE USAR EIVICHAIN?
          </p>

          <br />
          <br />
        </Grid>
      </Grid>
      <div className="container">
        {" "}
        <Grid container spacing={1}>
          <Grid
            item
            md={6}
            lg={4}
            xs={12}
            sm={12}
            style={{
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              display: "grid",
            }}
          >
            {" "}
            <div data-aos="fade-right">
              <MDBCard
                alignment="center"
                className="tec_card"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <MDBCardBody>
                  <br />
                  <br />
                  <MDBCardTitle>
                    <img src={trans} />
                  </MDBCardTitle>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#08843A",
                      fontWeight: "800",
                      fontSize: "22px",
                      textTransform: "uppercase",
                    }}
                  >
                    Transparencia
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    Comparte información clara
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    confiable para valorar tu
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    producto y su origen
                  </MDBCardText>
                  <br />
                  <br />
                </MDBCardBody>
              </MDBCard>
            </div>
            <br />
            <br />
            <br />
          </Grid>

          <Grid
            item
            md={6}
            lg={4}
            xs={12}
            sm={12}
            style={{
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              display: "grid",
            }}
          >
            {" "}
            <div data-aos="fade-up">
              {" "}
              <MDBCard
                alignment="center"
                className="tec_card"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <MDBCardBody>
                  <br />
                  <br />
                  <MDBCardTitle>
                    <img src={inter} />
                  </MDBCardTitle>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#08843A",
                      fontWeight: "800",
                      fontSize: "22px",
                      textTransform: "uppercase",
                    }}
                  >
                    Interconexión
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    Conecte la información de
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    todos los actores para que
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    estén fácilmente disponibles
                  </MDBCardText>
                  <br />
                  <br />
                </MDBCardBody>
              </MDBCard>
            </div>
            <br />
            <br />
            <br />
          </Grid>

          <Grid
            item
            md={12}
            lg={4}
            xs={12}
            sm={12}
            style={{
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              display: "grid",
            }}
          >
            {" "}
            <div data-aos="fade-right">
              {" "}
              <MDBCard
                alignment="center"
                className="tec_card"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <MDBCardBody>
                  <br />
                  <br />
                  <MDBCardTitle>
                    <img src={confia} />
                  </MDBCardTitle>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#08843A",
                      fontWeight: "800",
                      fontSize: "22px",
                      textTransform: "uppercase",
                    }}
                  >
                    CONFIANZA
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    una colaboración creada para
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    proteger y aumentar el valor del
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    producto a lo largo de su recorrido
                  </MDBCardText>
                  <br />
                  <br />
                </MDBCardBody>
              </MDBCard>
            </div>
            <br />
            <br />
            <br />
          </Grid>

          <br />
        </Grid>
      </div>

      <br />

      <div className="container">
        {" "}
        <Grid container spacing={1}>
          <Grid
            item
            md={6}
            lg={4}
            xs={12}
            sm={12}
            style={{
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              display: "grid",
            }}
          >
            {" "}
            <div data-aos="fade-right">
              {" "}
              <MDBCard
                alignment="center"
                className="tec_card"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <MDBCardBody>
                  <br />
                  <br />
                  <MDBCardTitle>
                    <img src={traz} />
                  </MDBCardTitle>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#08843A",
                      fontWeight: "800",
                      fontSize: "22px",
                      textTransform: "uppercase",
                    }}
                  >
                    Trazabilidad
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    Acceso en tiempo real a todos
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    los datos de la cadena para
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    simplificar y reducir costes
                  </MDBCardText>
                  <br />
                  <br />
                </MDBCardBody>
              </MDBCard>
            </div>
            <br />
            <br />
            <br />
          </Grid>

          <Grid
            item
            md={6}
            lg={4}
            xs={12}
            sm={12}
            style={{
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              display: "grid",
            }}
          >
            {" "}
            <div data-aos="fade-down">
              {" "}
              <MDBCard
                alignment="center"
                className="tec_card"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <MDBCardBody>
                  <br />
                  <br />
                  <MDBCardTitle>
                    <img src={vali} />
                  </MDBCardTitle>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#08843A",
                      fontWeight: "800",
                      fontSize: "22px",
                      textTransform: "uppercase",
                    }}
                  >
                    VALIDEZ JURÍDICA
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    Muchos países fomentan la
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    adopción de la tecnología
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    blockchain y le dan validez legal
                  </MDBCardText>
                  <br />
                  <br />
                </MDBCardBody>
              </MDBCard>
            </div>
            <br />
            <br />
            <br />
          </Grid>

          <Grid
            item
            md={12}
            lg={4}
            xs={12}
            sm={12}
            style={{
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              display: "grid",
            }}
          >
            {" "}
            <div data-aos="fade-right">
              {" "}
              <MDBCard
                alignment="center"
                className="tec_card"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <MDBCardBody>
                  <br />
                  <br />
                  <MDBCardTitle>
                    <img src={anti} />
                  </MDBCardTitle>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#08843A",
                      fontWeight: "800",
                      fontSize: "22px",
                      textTransform: "uppercase",
                    }}
                  >
                    Anti-falsificación
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    Procesos de producción confiables
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    para proteger la calidad del
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    producto para el consumidor.
                  </MDBCardText>
                  <br />
                  <br />
                </MDBCardBody>
              </MDBCard>
            </div>
            <br />
            <br />
            <br />
          </Grid>

          <br />
        </Grid>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default TechBlock;
