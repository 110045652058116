import React from "react";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import HeroLogo from "../../images/evfooter.png";

import facebook from "../../images/facebook.png";
import twitter from "../../images/twitter.png";
import telegram from "../../images/telegram.png";
import youtube from "../../images/youtube.png";
import medium from "../../images/medium.png";
import instagram from "../../images/instagram.png";
import discord from "../../images/discord.png";
import linked from "../../images/linked.png";

const Footer = () => {
  return (
    <MDBFooter
      className="text-center text-lg-start text-muted"
      style={{ background: " #F2F7E4" }}
    >
      <br />
      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <img src={HeroLogo} />
              </h6>
              <p className="footer_text">
                Es un registro público, distribuido y descentralizado donde se
                registran los intercambios y la información. Los datos una vez
                ingresados ​​al sistema y validados son imborrables e
                inalterables.
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6
                className="text-uppercase fw-bold mb-4"
                style={{ color: " #08843A" }}
              >
                Evichain
              </h6>
              <p>
                <a
                  href="./"
                  className=""
                  style={{ color: " #000", textDecoration: "none" }}
                >
                  Home
                </a>
                &nbsp; &nbsp;
                <a
                  href="./Tecnologia"
                  className=""
                  style={{ color: " #000", textDecoration: "none" }}
                >
                  Tecnología
                </a>
              </p>
              <p></p>
              <p>
                <a
                  href="./Oferta"
                  className=""
                  style={{ color: " #000", textDecoration: "none" }}
                >
                  Oferta
                </a>
                &nbsp; &nbsp;
                <a
                  href="./Contacto"
                  className=""
                  style={{ color: " #000", textDecoration: "none" }}
                >
                  Contacto
                </a>
              </p>
              <p></p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6
                className="text-uppercase fw-bold mb-4"
                style={{ color: " #08843A" }}
              >
                About
              </h6>
              <p>
                <a
                  href="#!"
                  className=""
                  style={{ color: " #000", textDecoration: "none" }}
                ></a>
              </p>
              <p>
                <a
                  href="#!"
                  className=""
                  style={{ color: " #000", textDecoration: "none" }}
                >
                  Terms of service
                </a>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
      <br />
    </MDBFooter>
  );
};

export default Footer;
