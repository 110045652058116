import React from "react";
import Header from "../Components/Navbar/Header";
import ContactForm from "../Components/ContactForm/ContactForm";

import Footer from "../Components/Footer/Footer";

const Pagefour = () => {
  return (
    <div>
      <Header />
      <ContactForm />
    </div>
  );
};

export default Pagefour;
