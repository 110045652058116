import React from "react";
import { Grid } from "@mui/material";
import arrow from "../../images/buttonarrow.png";
import Bounce from "react-reveal/Bounce";
const Contact = () => {
  return (
    <div className="structura_bg">
      <div className="container">
        <Grid container spacing={0}>
          <Grid
            item
            md={12}
            lg={12}
            xs={12}
            sm={12}
            style={{ justifyContent: "center", textAlign: "center" }}
          >
            <br />
            <br />
            <br />
            <br />
            <br />
            <Bounce top>
              <p style={{ color: "#000", fontSize: "15px" }}>CONTACTENOS</p>
              <p
                style={{
                  color: "#08843A",
                  fontWeight: "800",
                  fontSize: "20px",
                }}
              >
                LOS CONSUMIDORES QUIEREN
              </p>

              <p
                style={{
                  color: "#08843A",
                  fontWeight: "800",
                  fontSize: "20px",
                }}
              >
                CONOCER LA HISTORIA DEL PRODUCTO
              </p>
              <br />
              <p style={{ color: "#000", fontSize: "18px" }}>
                Hasta ahora, los consumidores no han tenido
                <br />
                herramientas a su disposicion para tomar
                <br />
                decisiones conscientes e informadas.
              </p>
              <br />
              <br />

              <button
                className="btn-grad"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                Contactenos <img src={arrow} />
              </button>
            </Bounce>
          </Grid>
        </Grid>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Contact;
