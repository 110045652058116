import React from "react";
import arrow from "../../images/buttonarrow.png";
import { Grid } from "@mui/material";
import Technologias from "../../images/Technologia.png";
import Bounce from "react-reveal/Bounce";
import Zoom from "react-reveal/Zoom";
import RubberBand from "react-reveal/RubberBand";
const Technologia = () => {
  return (
    <div className=" container" id="sec">
      <Grid container spacing={0}>
        <Grid md={6} lg={6} xs={12} sm={12}>
          <Bounce left>
            <br />
            <br />
            <p style={{ color: "#000", fontWeight: "400", fontSize: "14px" }}>
              TECNOLOGIA BLOCKCHAIN
            </p>
            <p
              style={{ color: "#08843A", fontWeight: "800", fontSize: "20px" }}
            >
              EL ECOSISTEMA EIVICHAIN
            </p>

            <p style={{ color: "#000", fontWeight: "400" }} className="Tech_p">
              La tecnología Blockchain representa una herramienta que aporta un
              real valor añadido en la construcción de una relación totalmente
              transparente entre productor y consumidor. Eivichain permite
              registrar cada paso en el ciclo de vida de un producto, desde el
              origen de las materias primas hasta su llegada al consumidor
              final. A través de Eivichain se pretende garantizar la integridad
              de la cadena de suministro y la protección de los productos contra
              la falsificación, además de aportar al consumidor final
              información realmente relevante. Al mismo tiempo, se impulsa la
              colaboración entre diferentes actores en un espacio de relación
              común como base de la descentralización.
            </p>

            <br />
          </Bounce>
        </Grid>

        <Grid
          md={6}
          lg={6}
          xs={12}
          sm={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            AlignItems: "center",
          }}
        >
          <br />
          <br />
          <Zoom>
            {" "}
            <RubberBand>
              <img
                src={Technologias}
                style={{
                  width: "50%",
                }}
              />
            </RubberBand>
          </Zoom>
        </Grid>

        <Grid
          md={12}
          lg={12}
          xs={12}
          sm={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            AlignItems: "center",
            display: "inline-grid",
          }}
        >
          <br />{" "}
          <button
            className="btn-grad"
            style={{
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <a href="./Tecnologia" style={{textDecoration:"none" , color:"#fff"}}>
              {" "}
              Saber Más <img src={arrow} />
            </a>
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Technologia;
