import React from "react";
import HeroLogo from "../../images/heromain.png";
import foodchain from "../../images/floc.png";
import quad from "../../images/flq.png";
import down from "../../images/scrolldown.png";
import Zoom from "react-reveal/Zoom";
import { Grid } from "@mui/material";
import "./Scroll.scss";

const HeroHome = () => {
  return (
    <div className="Hero_Home">
      <Grid container>
        <Grid
          lg={12}
          md={12}
          xs={12}
          sm={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            AlignItems: "center",
          }}
        >
          <br />
          <br />
          <br />

          <img src={HeroLogo} />
        </Grid>
        <Grid
          lg={12}
          md={12}
          xs={12}
          sm={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            AlignItems: "center",
          }}
        >
          <br />
          <br />
          <br />
          <p
            style={{
              justifyContent: "center",
              textAlign: "center",
              AlignItems: "center",

              color: "#fff",
            }}
            className="Hero_Text"
          >
            Llevamos la trazabilidad al siguiente nivel <br />
            gracias a la tecnología Blockchain.
          </p>
        </Grid>

        <Grid
          lg={12}
          md={12}
          xs={12}
          sm={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            AlignItems: "center",
          }}
        >
          <br />
          <br />
          <br />
          <br />
          <a href="#sec">
            <img src={down} className="img" />
          </a>
        </Grid>
        <div class="shadow"></div>
      </Grid>
      <br />
      <br />
    </div>
  );
};

export default HeroHome;
