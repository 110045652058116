import React from "react";
import HeroLogo from "../../images/interchange.png";
import { Grid } from "@mui/material";
import Bounce from "react-reveal/Bounce";
import Zoom from "react-reveal/Zoom";
const Intrechange = () => {
  return (
    <div className="Hero_link container">
      <Grid container spacing={2}>
        <Grid
          item
          md={12}
          lg={12}
          xs={12}
          sm={12}
          style={{ justifyContent: "center", textAlign: "center" }}
        >
          <Bounce top>
            <p style={{ color: "#000", fontWeight: "400", fontSize: "14px" }}>
              TECNOLOGIA BLOCKCHAIN
            </p>
            <p
              style={{
                color: "#08843A",
                fontWeight: "800",
                fontSize: "20px",
                textTransform: " uppercase",
              }}
            >
              LA INTEGRACIÓN DE EIVICHAIN EN LA CADENA DE SUMINISTRO
            </p>
          </Bounce>
          <br />
          <br />
          <Zoom bottom>
            {" "}
            <img src={HeroLogo} style={{ width: "70%" }} />
          </Zoom>

       
         
          
        </Grid>
      </Grid>
      <br />
    </div>
  );
};

export default Intrechange;
