import React from "react";
import { Grid } from "@mui/material";
import Productos from "../../images/dappinte.png";
import Bounce from "react-reveal/Bounce";
const DappIntegration = () => {
  return (
    <div className="container">
      <Grid container spacing={2}>
        <Grid item md={6} lg={6} xs={12} sm={12}>
          <br />
          <br />
          <br />
          <br />
          <Bounce left>
            <p
              style={{
                color: "#A8D43D",
                fontWeight: "800",
                fontSize: "20px",
                textTransform: "uppercase",
              }}
            >
              dApp Integrada
            </p>
            <p style={{ color: "#000", fontWeight: "400" }} className="Tech_p">
              Una oferta personalizada a la medida de tu negocio con sistemas
              automatizados, como IoT o sistemas gestionados ERP, que se pueden
              conectar perfectamente a la dApp. Foodchain puede integrar
              hardware y software existente, cualquier ERP y maquinaria.
            </p>

            <p
              style={{
                color: "#08843A",
                fontWeight: "300",
                fontSize: "15px",
                background: "#B1D63C42",
                padding: "30px",
                borderRadius: "10px",
              }}
            >
              Ejemplos: plantas de producción o ERP, invernaderos automatizados,
              cadenas de suministro completas o pasos de producción específicos.
              Para proporcionar transparencia y trazabilidad de 360°,
              comuníquese directamente con los clientes y proveedores hasta
              llegar al consumidor final.
            </p>
          </Bounce>
        </Grid>
        <Grid
          item
          md={6}
          lg={6}
          xs={12}
          sm={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            AlignItems: "center",
          }}
        >
          <br />
          <br />
          <div data-aos="flip-left">
            {" "}
            <img
              src={Productos}
              style={{
                width: "90%",
              }}
            />
          </div>

          <br />
          <br />
          <br />
        </Grid>
      </Grid>
    </div>
  );
};

export default DappIntegration;
