import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Swiper from "swiper";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Pageone from "./Page/Pageone";
import Pagetwo from "./Page/Pagetwo";
import Pagethree from "./Page/Pagethree";
import Pagefour from "./Page/Pagefour";

import "swiper/css";
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" exact={true} element={<Pageone />} />
          <Route path="/Tecnologia" element={<Pagetwo />} />
          <Route path="/Oferta" element={<Pagethree />} />
          <Route path="/Contacto" element={<Pagefour  />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
