import React from "react";

import Header from "../Components/Navbar/Header";
import HeroOferta from "../Components/HeroOferta/HeroOferta";
import TechnologiaOferta from "../Components/TechnologiaOferta/TechnologiaOferta";
import OfertaTech from "../Components/OfertaTech/OfertaTech";
import ContactOferta from "../Components/ContactOferta/ContactOferta";
import Cuenta from "../Components/Cuenta/Cuenta";
import DappIntegration from "../Components/DappIntegration/DappIntegration";
import SolucionesPerson from "../Components/SolucionePerson/SolucionesPerson";
import Footer from "../Components/Footer/Footer";
const Pagethree = () => {
  return (
    <div>
      <Header />
      <HeroOferta />
      <TechnologiaOferta />
      <OfertaTech />
      <Cuenta />
      <DappIntegration />
      <SolucionesPerson />
      <ContactOferta />

      <Footer />
    </div>
  );
};

export default Pagethree;
