import React from "react";
import { Grid } from "@mui/material";
const HeroOferta = () => {
  return (
    <div className="Hero_Oferta">
      <Grid container>
        <Grid
          lg={12}
          md={12}
          xs={12}
          sm={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            AlignItems: "center",
          }}
        >
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
          

          <p
            style={{
              justifyContent: "center",
              textAlign: "center",
              AlignItems: "center",

              color: "#fff",
            }}
            className="Hero_Tech_Text"
          >
            OFERTA
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Grid>
      </Grid>
    </div>
  );
};

export default HeroOferta;
