import React from "react";
import { Grid } from "@mui/material";
import Productos from "../../images/Producto.png";
import Bounce from "react-reveal/Bounce";
import consumer from "../../images/newtec.png";
import { borderRadius } from "@mui/system";
const Producto = () => {
  return (
    <div className="container">
      <Grid container spacing={2}>
        <Grid item md={6} lg={6} xs={12} sm={12}>
          <br />
          <br />
          <br />
          <br />
          <Bounce right>
            <p style={{ color: "#000", fontWeight: "400", fontSize: "14px" }}>
              PRODUCTO
            </p>
            <p
              style={{
                color: "#08843A",
                fontWeight: "800",
                fontSize: "20px",
                textTransform: "uppercase",
              }}
            >
              Etiquetas inteligentes
            </p>
            <p style={{ color: "#000", fontWeight: "600" }} className="Tech_p">
              El usuario, insertados los datos, crea etiquetas
            </p>
            <p style={{ color: "#000", fontWeight: "600" }} className="Tech_p">
              inteligentes con QR Code, tag RFID y/o tag NFC
            </p>
            <ul>
              <li>Insertable en cualquier formato</li>
              <li>Inmutable y con timestamp</li>
              <li>
                Diferentes niveles de accesibilidad, <br /> a discreción del
                propietario 
              </li>
            </ul>

            <p
              style={{
                color: "#08843A",
                fontWeight: "300",
                fontSize: "15px",
                background: "#B1D63C42",
                padding: "30px",
                borderRadius: "10px",
              }}
            >
              Desde las etiquetas inteligentes, el consumidor puede acceder a la
              información que el productor ha decidido poner a su disposición
            </p>
          </Bounce>
        </Grid>
        <Grid
          item
          md={6}
          lg={6}
          xs={12}
          sm={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            AlignItems: "center",
          }}
        >
          <br />
          <br />
          <div data-aos="zoom-in-up">
            {" "}
            <img
              src={Productos}
              style={{
                width: "100%",
              }}
            />
          </div>

          <br />
          <br />

          <br />
          <br />
          <br />
        </Grid>

        <Grid
          item
          md={12}
          lg={12}
          xs={12}
          sm={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            AlignItems: "center",
          }}
        >
          <br />
          <br />
          <div data-aos="flip-up">
            <img
              src={consumer}
              style={{
                width: "60%",
              }}
            />
          </div>
          <br />
          <br />
          <br />
        </Grid>
      </Grid>
    </div>
  );
};

export default Producto;
