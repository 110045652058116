import React from "react";
import Bounce from "react-reveal/Bounce";
import { Grid } from "@mui/material";
import Technologias from "../../images/mb.png";
const Contactenos = () => {
  return (
    <div className="container">
      <Grid container spacing={2}>
        <Grid
          item
          md={6}
          lg={6}
          xs={12}
          sm={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            AlignItems: "center",
          }}
        >
          <br />
          <br />
          <img
            src={Technologias}
            style={{
              width: "35%",
            }}
            className="img"
          />
        </Grid>
        <Grid item md={6} lg={6} xs={12} sm={12}>
          <br />
          <br />

          <br />
          <br />
          <Bounce right>
            <p style={{ color: "#000", fontWeight: "400", fontSize: "14px" }}>
              CONTÁCTENOS
            </p>
            <p
              style={{
                color: "#08843A",
                fontWeight: "800",
                fontSize: "20px",
                textTransform: "uppercase",
              }}
            >
              Una historia en la palma de tu mano
            </p>
            <p style={{ color: "#000", fontWeight: "400" }} className="Tech_p">
              Los códigos QR de Eivichain le permiten acceder a la historia
              completa e inmutable de un producto para descubrir su camino real
              a lo largo de todos los procesos de la cadena de suministro.
              <br />
              El blockchain conecta toda la información de la cadena de valor,
              la cristaliza y la pone a disposición de cualquier usuario
              equipado con smartphone y lector de códigos QR. A través de la
              plataforma de Eivichain, todos los documentos, fotos, videos y
              otros detalles relacionados con el producto están disponibles de
              inmediato.
            </p>
          </Bounce>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contactenos;
