import React from "react";
import { Grid } from "@mui/material";
import Technologias from "../../images/mb.png";
import Bounce from "react-reveal/Bounce";

const Partner = () => {
  return (
    <div className="container">
      <Grid container spacing={2}>
        <Grid
          item
          md={12}
          lg={12}
          xs={12}
          sm={12}
          className="partner_alignment_text"
          style={{ justifyContent: "center", textAlign: "center" }}
        >
          <br />
          <br />
          <br />
          <br />
          <Bounce right>
           
            <p
              style={{
                color: "#08843A",
                fontWeight: "800",
                fontSize: "20px",
                textTransform: "uppercase",
              }}
            >
              NUESTROS PARTNERS
            </p>
            <p style={{ color: "#000", fontWeight: "400", fontSize: "14px" }}>
           Coming Soon
          </p>
          </Bounce>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Grid>
        <Grid
          item
          md={6}
          lg={6}
          xs={12}
          sm={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            AlignItems: "center",
          }}
        >
          <br />
          <br />
          <br />
        </Grid>
      </Grid>
    </div>
  );
};

export default Partner;
