import React from "react";
import { Grid } from "@mui/material";
const ContactOferta = () => {
  return (
    <div className="structura_bg_oferta">
      <div className="container">
        <Grid container spacing={0}>
          <Grid
            item
            md={12}
            lg={12}
            xs={12}
            sm={12}
            style={{ justifyContent: "center", textAlign: "center" }}
          >
            <br />
            <br />
            <br />
            <br />
            <br />
            <div data-aos="zoom-in">
              <p
                style={{
                  color: "#08843A",
                  fontWeight: "800",
                  fontSize: "20px",
                  textTransform: "uppercase",
                }}
              >
                CONTÁCTENOS
              </p>
              <br />
              <p style={{ color: "#000", fontSize: "18px" }}>
                Hasta ahora, los consumidores no han tenido
                <br />
                herramientas a su disposicion para tomar
                <br />
                decisiones conscientes e informadas.
              </p>
            </div>
            <br />
          </Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
};

export default ContactOferta;
