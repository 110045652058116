import React from "react";
import { Grid } from "@mui/material";
import cuenta from "../../images/Cuenta.png";
import dapp from "../../images/dapp.png";
import soluciones from "../../images/soluciones.png";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-react-ui-kit";

const OfertaTech = () => {
  return (
    <div container>
      <div className="container">
        {" "}
        <Grid container spacing={1}>
          <Grid
            item
            md={6}
            lg={4}
            xs={12}
            sm={12}
            style={{
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              display: "grid",
            }}
          >
            {" "}
            <div data-aos="fade-up-right">
              {" "}
              <MDBCard className="tec_card">
                <MDBCardBody>
                  <br />
                  <br />
                  <MDBCardTitle>
                    <img src={cuenta} />
                  </MDBCardTitle>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#53C44D",
                      fontWeight: "800",
                      fontSize: "26px",
                    }}
                  >
                    Cuenta
                  </MDBCardText>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    Acceda a la plataforma Eivichain para:
                  </MDBCardText>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "12px",
                      textAlign: "left",
                    }}
                  >
                    Seguimiento de productos a
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "12px",
                      textAlign: "left",
                    }}
                  >
                    través de almacenes
                  </MDBCardText>

                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "12px",
                      textAlign: "left",
                    }}
                  >
                    Gestionar productos y
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "12px",
                      textAlign: "left",
                    }}
                  >
                    procesos.
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "12px",
                      textAlign: "left",
                    }}
                  >
                    Supervisar toda la cadena de
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "12px",
                      textAlign: "left",
                    }}
                  >
                    suministro
                  </MDBCardText>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    Ingreso manual de datos a la plataforma.
                  </MDBCardText>

                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    Configuración inicial + Cuota anual.
                  </MDBCardText>
                  <br />
                  <br />
                  <br />
                </MDBCardBody>
              </MDBCard>
            </div>
            <br />
            <br />
            <br />
            <br />
          </Grid>

          <Grid
            item
            md={6}
            lg={4}
            xs={12}
            sm={12}
            style={{
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              display: "grid",
            }}
          >
            <div data-aos="zoom-in">
              {" "}
              <MDBCard className="tec_card">
                <MDBCardBody>
                  <br />
                  <br />
                  <MDBCardTitle>
                    <img src={dapp} />
                  </MDBCardTitle>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#A8D43D",
                      fontWeight: "800",
                      fontSize: "26px",
                    }}
                  >
                    DAPP INTEGRADE
                  </MDBCardText>

                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    La plataforma Eivichain
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    integrada con sus dispositivos ERP e IoT.
                  </MDBCardText>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    Integración con software y
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    hardware existentes que ya se
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    utilizan en su negocio.
                  </MDBCardText>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    Funcionalidades adicionales
                  </MDBCardText>

                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    basadas en tecnologías
                  </MDBCardText>

                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    innovadoras (AI - M2M).
                  </MDBCardText>

                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    Entrada de datos automatizada.
                  </MDBCardText>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    Desarrollo + Cuota anual + Plug-in (opcional).
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </div>{" "}
            <br />
            <br />
            <br />
            <br />
          </Grid>

          <Grid
            item
            md={12}
            lg={4}
            xs={12}
            sm={12}
            style={{
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              display: "grid",
            }}
          >
            {" "}
            <div data-aos="fade-up-right">
              {" "}
              <MDBCard className="tec_card">
                <MDBCardBody>
                  <br />
                  <br />
                  <MDBCardTitle>
                    <img src={soluciones} />
                  </MDBCardTitle>
                  <br />
                  <MDBCardText
                    style={{
                      color: " #08843A",
                      fontWeight: "800",
                      fontSize: "26px",
                      textTransform:"uppercase"
                    }}
                  >
                    Soluciones
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: " #08843A",
                      fontWeight: "800",
                      fontSize: "26px",
                      textTransform:"uppercase"
                    }}
                  >
                    personalizadas
                  </MDBCardText>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    Desarrollamos tu solución en blockchain
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    para crear nuevos modelos de negocio
                  </MDBCardText>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    Consulta para el diseño de
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    plataformas innovadoras
                  </MDBCardText>
                  <br />
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    Estudio y desarrollo de
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    contratos inteligentes para
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    negocios específicos
                  </MDBCardText>
                  <MDBCardText
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  >
                    A evaluar según las necesidades del cliente.
                  </MDBCardText>

                  <br />
                </MDBCardBody>
              </MDBCard>
            </div>
            <br />
            <br />
            <br />
            <br />
          </Grid>

          <br />
        </Grid>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default OfertaTech;
